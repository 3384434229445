import Vue from 'vue';

const initialState = () => ({
  relatorios: [],
  loading: false,
});

const state = initialState();

const getters = {
  getRelatorios(state) {
    return state.relatorios;
  },

  getLoading: (state) => state.loading,
};

const actions = {
  gerarRelatorios(_, { email, listaRelatorio }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = '/relatorio/v1/gerar';
      const res = await comunicacao.send(url, {
        method: 'POST',
        data: { email, listaRelatorio },
      });
      if ([200, 201].includes(res.status)) {
        resolve({
          status: 200,
          mensagem: res.data.mensagem,
          retorno: res.data.retorno,
          download: (res.data.retorno || {}).acessoURL,
        });
      } else {
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
          retorno: res.data.retorno || null,
        });
      }
    });
  },

  fetchRelatorios({ commit }, { data }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const url = '/relatorio/v1/listar';
      const resposta = await comunicacao.send(url, { method: 'GET', data });

      if ([200, 201].includes(resposta.data.status)) {
        if (data.setCommit) commit('setRelatorios', resposta.data.retorno);
        resolve({ status: 200, mensagem: 'Lista de Relatórios recuperado com sucesso', retorno: resposta.data.retorno });
      } else {
        if (data.setCommit) commit('setRelatorios', []);
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem });
      }
    });
  }
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },

  setRelatorios(state, data) {
    state.relatorios = data;
  },

  setLoading(state, data) {
    state.loading = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
