export default [
  {
    titulo: 'Home',
    condicao: () => true,
    icone: 'fas fa-home',
    caminho: 'missoes',
    paginas: [],
  },
  {
    titulo: 'Missões',
    condicao: () => true,
    icone: 'fas fa-bullseye',
    caminho: 'missoes',
    paginas: [],
  },
  {
    titulo: 'Níveis',
    condicao: () => true,
    icone: 'fas fa-medal',
    caminho: 'niveis',
    paginas: [],
  },
];
