import DashboardLayout from '@/layout/DashboardLayout';
import AuthLayout from '@/layout/AuthLayout';

import { deslogado, logado, acessoPagina } from './middlewares';

import { Servico } from '../lib/Storage';

const verificarRota = () => {
  let rotaInicial = null;

  const servicoAtivo = Servico.obterServicoAtivo();

  if (servicoAtivo === 'CASHBACK') rotaInicial = 'cashback';
  if (servicoAtivo === 'GAMEFICACAO') rotaInicial = 'missoes';
  if (servicoAtivo === 'SORTEIOS') rotaInicial = 'dashboard';
  if (servicoAtivo === 'CRM') rotaInicial = 'crm';
  if (servicoAtivo === 'CASHBACKLIGHT') rotaInicial = 'cashbacklight';

  return rotaInicial ? rotaInicial : 'dashboard';
};

export default [
  {
    path: './',
    redirect: 'login',
    component: AuthLayout,

    children: [
      {
        path: '/login',
        name: 'login',
        meta: {
          middleware: deslogado,
          desc: 'login',
        },
        component: () => import('../views/Login.vue'),
      },
      {
        path: '/redefinirSenha',
        name: 'redefinirSenha',
        meta: {
          middleware: deslogado,
          desc: 'redefinirSenha',
        },
        component: () => import('../views/RedefinirSenha.vue'),
      },
    ],
  },

  {
    path: '/',
    redirect: verificarRota(),
    component: DashboardLayout,

    meta: {
      middleware: [logado, acessoPagina],
    },

    children: [
      {
        path: '/suporte',
        name: 'suporte',
        meta: {
          desc: 'dashboard',
        },
        component: () => import('../views/Suporte.vue'),
      },

      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          desc: 'dashboard',
        },
        component: () => import('../views/Dashboard.vue'),
      },
      {
        path: '/rankingAssociados',
        name: 'RankingAssociados',
        meta: {
          desc: 'Ranking de lojas',
        },
        component: () => import('../views/RankingAssociados.vue'),
      },
      {
        path: '/rankingProdutos',
        name: 'RankingProdutos',
        meta: {
          desc: 'Ranking de produtos',
        },
        component: () => import('../views/RankingDeProdutosDocFiscal.vue'),
      },
      {
        path: '/rankingVotacao',
        name: 'RankingVotacao',
        meta: {
          desc: 'Votação de associados',
        },
        component: () => import('../views/RankingVotacao.vue'),
      },
      {
        path: '/documentosFiscais',
        name: 'documentosFiscais',
        meta: {
          desc: 'Lista de Vendas',
        },
        component: () => import('../views/DocumentosFiscais.vue'),
      },
      {
        path: '/documentosFiscais/:chaveAcessoCOO/visualizar',
        name: 'documentosFiscais',
        meta: {
          desc: 'Lista de Vendas',
        },
        component: () => import('../views/DocumentosFiscais.vue'),
      },
      {
        path: '/validar-venda',
        name: 'validarVenda',
        meta: {
          desc: 'Ver Venda',
        },
        component: () => import('../views/ValidacaoVenda.vue'),
      },
      {
        path: '/rankingLojas',
        name: 'RankingLojas',
        component: () => import('../views/RankingLojas.vue'),
      },
      {
        path: '/investimentoporcampanha',
        name: 'InvestimentoPorCampanha',
        meta: {
          desc: 'Ranking de investimento por campanha',
        },
        component: () => import('../views/InvestimentoPorCampanha.vue'),
      },
      {
        path: '/documentosFiscaisOrigem',
        name: 'documentosFiscaisOrigem',
        meta: {
          desc: 'Documentos Fiscais Origem',
        },
        component: () => import('../views/DocumentosFiscaisOrigem.vue'),
      },
      {
        path: '/documentosFiscaisPendentes',
        name: 'documentosFiscaisPendentes',
        alias: '/retornoCrawler',
        meta: {
          desc: 'Documentos Fiscais Pendentes',
        },
        component: () => import('../views/DocumentosFiscaisPendentes.vue'),
      },
      {
        path: '/docsFiscaisEmitente',
        name: 'docsFiscaisEmitente',
        meta: {
          desc: 'Resumo de Documentos Fiscais por Emitente',
        },
        component: () => import('../views/DocumentosFiscaisEmitente.vue'),
      },
      {
        path: '/adesoes',
        name: 'adesoes',
        meta: {
          desc: 'lojas',
        },
        component: () => import('../views/Adesoes.vue'),
      },
      {
        path: '/listarSaldo',
        name: 'ListarSaldo',
        meta: {
          desc: 'Saldos dos usuários',
        },
        component: () => import('../views/ListarSaldos.vue'),
      },
      {
        path: '/logins',
        name: 'logins',
        meta: {
          desc: 'logins administrativos',
        },
        component: () => import('../views/Logins.vue'),
      },
      {
        path: '/listagemProdutos',
        name: 'listagemProdutos',
        meta: {
          desc: 'Lista de compras realizadas pelo consumidor',
        },
        component: () => import('../views/ListagemProdutos.vue'),
      },
      {
        path: '/listaProdutos',
        name: 'listaProdutos',
        meta: {
          desc: 'Listas de Consumidores',
        },
        component: () => import('../views/ListaProdutos.vue'),
      },
      {
        path: '/adesoes/:search/',
        name: 'adesoes',
        meta: {
          desc: 'lojas',
        },
        component: () => import('../views/Adesoes.vue'),
      },
      {
        path: '/adesoes/:search/:acao',
        name: 'adesoes',
        meta: {
          desc: 'lojas',
        },
        component: () => import('../views/Adesoes.vue'),
      },
      {
        path: '/sorteios',
        name: 'sorteios',
        meta: {
          desc: 'sorteios',
        },
        component: () => import('../views/Sorteios.vue'),
      },
      {
        path: '/sorteios/:sorteioID/resultado',
        name: 'sorteios',
        meta: {
          desc: 'sorteios',
        },
        component: () => import('../views/Sorteios.vue'),
      },
      {
        path: '/sorteiosAproximacao',
        name: 'sorteios',
        meta: {
          desc: 'sorteios',
        },
        component: () => import('../views/Sorteios.vue'),
      },
      // {
      //   path: '/sorteiosAproximacao',
      //   name: 'sorteiosAproximacao',
      //   meta: {
      //     desc: 'sorteios realizados por aproximação',
      //   },
      //   component: () => import('../views/SorteioAprox.vue'),
      // },
      {
        path: '/campanhas',
        name: 'campanhas',
        meta: {
          desc: 'campanhas',
        },
        component: () => import('../views/Campanha.vue'),
      },
      {
        path: '/cupons',
        name: 'cupons escaneados',
        meta: {
          desc: 'cupons escaneados',
        },
        component: () => import('../views/Cupons.vue'),
      },
      {
        path: '/cupons/numeroSequencial/:numero',
        name: 'cupons escaneados',
        meta: {
          desc: 'cupons escaneados',
        },
        component: () => import('../views/Cupons.vue'),
      },
      {
        path: '/cupons/id/:id',
        name: 'cupons escaneados',
        meta: {
          desc: 'cupons escaneados',
        },
        component: () => import('../views/Cupons.vue'),
      },
      {
        path: '/cupons/consumidor/:consumidor',
        name: 'cupons escaneados',
        meta: {
          desc: 'cupons escaneados',
        },
        component: () => import('../views/Cupons.vue'),
      },
      {
        path: '/consumidores',
        name: 'consumidores',
        meta: {
          desc: 'consumidores',
        },
        component: () => import('../views/Consumidores.vue'),
      },
      {
        path: '/configRaspadinha',
        name: 'configRaspadinha',
        meta: {
          desc: 'configurações raspadinha',
        },
        component: () => import('../views/ConfiguracoesRaspadinhaAlt.vue'),
      },
      {
        path: '/configCompraRaspadinha',
        name: 'configCompraRaspadinha',
        meta: {
          desc: 'configurações compra/raspadinha',
        },
        component: () => import('../views/ConfiguracoesCompraRaspadinha.vue'),
      },
      {
        path: '/configCupom',
        name: 'configCupom',
        meta: {
          desc: 'configurações cupom',
        },
        component: () => import('../views/ConfiguracoesCupons.vue'),
      },
      {
        path: '/configProduto',
        name: 'configProduto',
        meta: {
          desc: 'configurações de cupons por produto',
        },
        component: () => import('../views/ConfiguracoesProdutos.vue'),
      },
      {
        path: '/configDocumentoFiscal',
        name: 'configDocumentoFiscal',
        meta: {
          desc: 'configurações de documento fiscal',
        },
        component: () => import('../views/ConfiguracoesDocumentoFiscal.vue'),
      },
      {
        path: '/configuracoesNumerosDaSorte',
        name: 'configuracoesNumerosDaSorte',
        meta: {
          desc: 'configurações de números da sorte',
        },
        component: () => import('../views/ConfiguracoesNumerosDaSorte.vue'),
      },
      {
        path: '/configuracoesNumerosAleatorios',
        name: 'configuracoesNumerosAleatorios',
        meta: {
          desc: 'configurações de números aleatórios',
        },
        component: () => import('../views/ConfiguracoesNumerosAleatorios.vue'),
      },
      {
        path: '/arquivos',
        name: 'arquivos',
        meta: {
          desc: 'Consulta de arquivos',
        },
        component: () => import('../views/Arquivos.vue'),
      },
      {
        path: '/industrias',
        name: 'industrias',
        meta: {
          desc: 'Cadastro de industrias',
        },
        component: () => import('../views/Industrias.vue'),
      },
      {
        path: '/redes',
        name: 'redes',
        meta: {
          desc: 'Cadastro de redes',
        },
        component: () => import('../views/Redes.vue'),
      },
      {
        path: '/grupos',
        name: 'cadastroGrupos',
        meta: {
          desc: 'Gerenciamento dos grupos',
        },
        component: () => import('../views/Grupos.vue'),
      },
      {
        path: '/compras',
        name: 'cadastrarCompras',
        meta: {
          desc: 'Cadastro de compras',
        },
        component: () => import('../views/CadastrarCompras.vue'),
      },
      {
        path: '/controleMetas',
        name: 'controleMetas',
        meta: {
          desc: 'Controle de Metas',
        },
        component: () => import('../views/ControleMetas.vue'),
      },
      {
        path: '/cadastrarPremios',
        name: 'cadastrarPremios',
        meta: {
          desc: 'Cadastro de prêmios',
        },
        component: () => import('../views/CadastrarPremios.vue'),
      },
      {
        path: '/premiacoesRaspadinhas',
        name: 'premiacoesRaspadinhas',
        meta: {
          desc: 'Premiações das raspadinhas',
        },
        component: () => import('../views/PremiacoesRaspadinhas.vue'),
      },
      {
        path: '/consumidores/:search',
        name: 'consumidores',
        meta: {
          desc: 'consumidores',
        },
        component: () => import('../views/Consumidores.vue'),
      },
      {
        path: '/consumidores/:search/:acao',
        name: 'consumidores',
        meta: {
          desc: 'consumidores',
        },
        component: () => import('../views/Consumidores.vue'),
      },
      {
        path: '/raspadinhasAlternativas',
        name: 'PremiosInstantaneos',
        meta: {
          desc: 'Prêmios Instantâneos',
        },
        component: () => import('../views/PremiosInstantaneos.vue'),
        // component: () => import('../views/RaspadinhasAlternativas.vue'),
      },
      {
        path: '/premios-instantaneos',
        name: 'PremiosInstantaneos',
        meta: {
          desc: 'Prêmios Instantâneos',
        },
        component: () => import('../views/PremiosInstantaneos.vue'),
      },
      {
        path: '/_relatorios',
        name: 'Relatorios',
        meta: {
          desc: 'Relatórios',
        },
        component: () => import('../views/_Relatorios.vue'),
      },
      {
        path: '/relatorios',
        name: 'Relatorios',
        meta: {
          desc: 'Relatórios',
        },
        component: () => import('../views/Relatorios.vue'),
      },
      {
        path: '/gerar-relatorios',
        name: 'GerarRelatorios',
        meta: {
          desc: 'Gerar Relatórios',
        },
        component: () => import('../views/GerarRelatorios.vue'),
      },
      {
        path: '/listar-relatorios',
        name: 'listarRelatorios',
        meta: {
          desc: 'Listar Relatórios',
        },
        component: () => import('../views/ListarRelatorios.vue'),
      },
      {
        path: '/listar-relatorios/:idRelatorio',
        name: 'listarRelatorios',
        meta: {
          desc: 'Listar Relatórios',
        },
        component: () => import('../views/ListarRelatorios.vue'),
      },
      {
        path: '/rankingDocumentosFiscais',
        name: 'rankingDocumentosFiscais',
        meta: {
          desc: 'Ranking documentos fiscais',
        },
        component: () => import('../views/RankingDocsFiscais.vue'),
      },
      {
        path: '/produtos',
        name: 'produtos',
        meta: {
          desc: 'Produtos',
        },
        component: () => import('../views/Produtos.vue'),
      },
      {
        path: '/questionario',
        name: 'questionario',
        meta: {
          desc: 'Questionário',
        },
        component: () => import('../views/Questionario.vue'),
      },
      {
        path: '/cedulas',
        name: 'cedulas',
        meta: {
          desc: 'Cédulas',
        },
        component: () => import('../views/Cedulas.vue'),
      },
      {
        path: '/chat',
        name: 'chat',
        meta: {
          desc: 'Chat',
        },
        component: () => import('../views/Chat.vue'),
      },
      {
        path: '/chat/:usuario',
        name: 'chat',
        meta: {
          desc: 'Chat',
        },
        component: () => import('../views/Chat.vue'),
      },
      {
        path: '/email',
        name: 'Email',
        meta: {
          desc: 'E-mail',
        },
        component: () => import('../views/Email.vue'),
      },
      {
        path: '/homeIndustria',
        name: 'Home',
        meta: {
          desc: 'Home',
        },
        component: () => import('../views/HomeIndustria.vue'),
      },
      {
        path: '/homeRede',
        name: 'homeRede',
        meta: {
          desc: 'Home Rede',
        },
        component: () => import('../views/HomeRede.vue'),
      },
      {
        path: '/biAdmin',
        name: 'biAdmin',
        meta: {
          desc: 'BI Admin',
        },
        component: () => import('../views/BiAdmin.vue'),
      },
      {
        path: '/importarVendas',
        name: 'importarVendas',
        meta: {
          desc: 'Importação de vendas',
        },
        component: () => import('../views/ImportacaoVendas.vue'),
      },
      {
        path: '/ImportarCashin',
        name: 'ImportarCashin',
        meta: {
          desc: 'ImportacaoCashin',
        },
        component: () => import('../views/ImportacaoCashIn.vue'),
      },
      {
        path: '/ImportarCashout',
        name: 'ImportarCashout',
        meta: {
          desc: 'ImportacaoCashout',
        },
        component: () => import('../views/ImportacaoCashout.vue'),
      },
      {
        path: '/resultadoVendedores',
        name: 'resultadoVendedores',
        component: () => import('../views/ResultadoVendedores.vue'),
        meta: {
          desc: 'Resultado dos vendedores',
        },
      },
      {
        path: '/aceites',
        name: 'aceites',
        meta: {
          desc: 'aceites',
        },
        component: () => import('../views/Aceites.vue'),
      },
      {
        path: '/visualizarResultadoSetorDepartamento',
        name: 'visualizarResultadoSetorDepartamento',
        component: () => import('../views/Cashback/Resultados/Setor/VisualizarSetor/index.vue'),
        props: true,
      },
      {
        path: '/categorias',
        name: 'cadastroCategorias',
        meta: {
          desc: 'Gerenciamento das categorias',
        },
        component: () => import('../views/Categorias.vue'),
      },

      {
        path: '/logs/requisicao',
        name: 'logs',
        meta: {
          desc: 'Página administrativa com logs de operações do sistema',
        },
        component: () => import('../views/LogsRequisicao.vue'),
      },

      {
        path: '/logs/importacao',
        name: 'logsImportacao',
        meta: {
          desc: 'Página administrativa com logs de importações',
        },
        component: () => import('../views/LogsImportacao.vue'),
      },
    ],
  },

  {
    path: '/cashback',
    redirect: verificarRota(),
    meta: {
      middleware: [logado, acessoPagina],
    },

    component: DashboardLayout,
    children: [
      {
        path: '/',
        name: 'estatisticasCashback',
        component: () => import('../views/Cashback/PaginaInicial.vue'),
      },
      {
        path: '/campanhaCashback/:slug',
        name: 'visualizarCampanhaCashback',
        component: () => import('../views/Cashback/CampanhaCashback/Visualizar.vue'),
      },
      {
        path: '/listaCampanhaCashback',
        name: 'listaCampanhaCashback',
        component: () => import('../views/Cashback/CampanhaCashback/Listar.vue'),
        meta: {
          desc: 'Campanhas Cashback',
        },
      },
      {
        path: '/cadastrarCampanhaCashback',
        name: 'cadatrarCampanhaCashback',
        component: () => import('../views/Cashback/CampanhaCashback/Cadastrar.vue'),
        meta: {
          desc: 'Cadastrar campanha cashback',
        },
      },
      {
        path: '/editarCampanhaCashback',
        name: 'editarCampanhaCashback',
        component: () => import('../views/Cashback/CampanhaCashback/Editar.vue'),
        meta: {
          desc: 'Editar configurações da campanha cashback',
        },
      },
      {
        path: '/controlePedidos',
        name: 'controlePedidos',
        component: () => import('../views/Cashback/Fidelidade/Pedidos/ControlePedidos.vue'),
        meta: {
          desc: 'Controle de Pedidos',
        },
      },
      {
        path: '/responsaveis',
        name: 'responsaveisCampanhaCashback',
        component: () => import('../views/Cashback/Responsaveis.vue'),
        meta: {
          desc: 'Responsáveis',
        },
      },
      {
        path: '/controleEstoque',
        name: 'controleEstoque',
        component: () => import('../views/Cashback/Fidelidade/Estoque/ControleEstoque.vue'),
        meta: {
          desc: 'Controle de Estoque',
        },
      },
      {
        path: '/trocarPontos',
        name: 'trocarPontosEmPremiosCashback',
        component: () => import('../views/Cashback/TrocarPontos.vue'),
        meta: {
          desc: 'Realizar cashout',
        },
      },
      {
        path: '/controleProdutos',
        name: 'controleProdutos',
        component: () => import('../views/Cashback/Fidelidade/Produtos/ControleProdutos.vue'),
        meta: {
          desc: 'Realize o controle de produtos',
        },
      },
      {
        path: '/colaboradores',
        name: 'colaboradoresCampanhaCashback',
        component: () => import('../views/Cashback/Colaboradores.vue'),
        meta: {
          desc: 'Colaboradores Cashback',
        },
      },
      {
        path: '/extratoCashback',
        name: 'extratoCashbackCampanhaCashback',
        component: () => import('../views/Cashback/ExtratoCashback.vue'),
        meta: {
          desc: 'Extrato dos usuários',
        },
      },
      {
        path: '/saldosCashback',
        name: 'saldosCashback',
        component: () => import('../views/Cashback/SaldosCashback.vue'),
        meta: {
          desc: 'Saldos de cashback',
        },
      },
      {
        path: '/resultados',
        name: 'resultadoCashback',
        component: () => import('../views/Cashback/Resultados/index.vue'),
        children: [
          {
            path: '/resultadoSetorDepartamento',
            name: 'resultadoSetorDepartamento',
            component: () => import('../views/Cashback/Resultados/Setor/Ranking.vue'),
          },
          {
            path: '/visualizarResultadoSetorDepartamento/:id',
            name: 'visualizarResultadoSetorDepartamento',
            component: () => import('../views/Cashback/Resultados/Setor/VisualizarSetor/index.vue'),
          },
        ],
      },
      {
        path: '/ProvedoresDeVoucher',
        name: 'ProvedoresDeVoucher',
        component: () => import('../views/Cashback/ProvedoresDeVoucher.vue'),
        meta: {
          desc: 'Provedores de Voucher',
        },
      },
      {
        path: '/pedidosVoucher',
        name: 'pedidosVoucher',
        component: () => import('../views/Cashback/PedidosVoucher.vue'),
        meta: {
          desc: 'Pedidos de Voucher',
        },
      },
    ],
  },

  {
    path: '/crm',
    name: 'CRM',
    meta: {
      requiresAuth: true,
    },
    component: DashboardLayout,
    children: [
      {
        path: '/',
        name: 'crm',
        meta: {
          desc: 'Notificações',
        },
        component: () => import('../views/CRM/Notificacoes.vue'),
      },
      {
        path: '/crm/notificacoes',
        name: 'notificacoes',
        meta: {
          desc: 'Enviar notificação',
        },
        component: () => import('../views/CRM/Notificacoes.vue'),
      },
      {
        path: '/crm/grupos',
        name: 'grupos',
        meta: {
          desc: 'Grupos',
        },
        component: () => import('../views/CRM/Grupos.vue'),
      },

      {
        path: '/crm/email/listaModelos',
        name: 'listaModelosEmail',
        meta: {
          desc: 'Modelos de Email',
        },
        component: () => import('../views/CRM/Email/ListaModelos.vue'),
      },
      {
        path: '/crm/integracoes',
        name: 'integracoes',
        meta: {
          desc: 'Integrações',
        },
        component: () => import('../views/CRM/Integracoes.vue'),
      },
    ],
  },

  {
    path: '/cashbacklight',
    meta: {
      requiresAuth: true,
    },
    component: DashboardLayout,
    children: [
      {
        path: '/',
        name: 'cashbacklight',
        component: () => import('../views/CashbackLight/home/Index.vue'),
      },

      {
        path: '/consumidores',
        name: 'cashback-light-consumidores',
        meta: {
          desc: 'consumidores',
        },
        component: () => import('../views/Consumidores.vue'),
      },
      {
        path: '/consumidores/:search',
        name: 'cashback-light-consumidores-pesquisar',
        meta: {
          desc: 'consumidores',
        },
        component: () => import('../views/Consumidores.vue'),
      },
      {
        path: '/consumidores/:search/:acao',
        name: 'cashback-light-consumidores-pesquisar-acao',
        meta: {
          desc: 'consumidores',
        },
        component: () => import('../views/Consumidores.vue'),
      },

      {
        path: '/vendas',
        name: 'cashback-light-vendas',
        meta: {
          desc: 'Lista de Vendas',
        },
        component: () => import('../views/DocumentosFiscais.vue'),
      },
      {
        path: '/vendas/:chaveAcessoCOO/visualizar',
        name: 'cashback-light-vendas-visualizar',
        meta: {
          desc: 'Lista de Vendas',
        },
        component: () => import('../views/DocumentosFiscais.vue'),
      },

      {
        path: '/saldosCashback',
        name: 'cashback-light-saldos',
        component: () => import('../views/Cashback/SaldosCashback.vue'),
        meta: {
          desc: 'Saldos dos usuários',
        },
      },
      {
        path: '/notificacoes',
        name: 'cashback-light-notificacoes',
        meta: {
          desc: 'Enviar notificação',
        },
        component: () => import('../views/CRM/Notificacoes.vue'),
      },
      {
        path: '/grupos-notificacoes',
        name: 'cashback-light-grupos',
        meta: {
          desc: 'Grupos',
        },
        component: () => import('../views/CRM/Grupos.vue'),
      },
      {
        path: '/configuracoes',
        name: 'configuracoes',
        meta: {
          desc: 'Configuracoes',
        },
        component: () => import('../views/Configuracoes.vue'),
      },
    ],
  },

  {
    path: '/gameficacao',
    meta: {
      requiresAuth: true,
    },
    component: DashboardLayout,
    children: [
      {
        path: '/',
        name: 'gameficacao',
        component: () => import('../views/Gamificacao/MissoesListar.vue'),
      },
      {
        path: '/missoes',
        name: 'missoes',
        component: () => import('../views/Gamificacao/MissoesListar.vue'),
      },
      {
        path: '/missao',
        name: 'missao',
        component: () => import('../views/Gamificacao/Missao.vue'),
      },

      {
        path: '/niveis',
        name: 'niveis',
        component: () => import('../views/Gamificacao/NiveisListar.vue'),
      },
      {
        path: '/nivel',
        name: 'nivel',
        component: () => import('../views/Gamificacao/Niveis.vue'),
      },
    ],
  },

  {
    path: '*',
    component: () => import('../views/Errors/404.vue'),
  },
];
