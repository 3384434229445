import Vue from 'vue';

// Object initial state
const initialState = () => ({
  carregando: false,
  raspadinhas: [],
  scratchCanvassUnit: null,
  scratchCanvassTotal: 0,
  queryScratch: null,
  listagemRaspadinhas: [],
  raspadinhasUsuario: [],
  totalRaspadinha: 0,
  totalListagemRaspadinha: 0,
  erro: null,
});

// State object
const state = initialState();

// Getter object
const getters = {
  getRaspadinhas(state) {
    return state.raspadinhas;
  },
  getListagemRaspadinhas(state) {
    return state.listagemRaspadinhas;
  },
  getRaspadinhasUsuario(state) {
    return state.raspadinhasUsuario;
  },
  getTotalizador(state) {
    return state.totalRaspadinha;
  },
  getTotalizadorListagemRaspadinha(state) {
    return state.totalListagemRaspadinha;
  },
  getScratchCanvassUnit(state) {
    return state.scratchCanvassUnit;
  },
  getScratchCanvassTotal(state) {
    return state.scratchCanvassTotal;
  },
  getQueryScratch(state) {
    return state.queryScratch;
  },
  getLoader(state) {
    return state.carregando;
  },
  getErro(state) {
    return state.erro;
  },
};

// Actions
const actions = {
  fetchRaspadinhas({ commit }, { campanha, filtro, paginacao }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/raspadinhas/${campanha.ano}/${campanha.identificacao}/${paginacao.porPagina}/${paginacao.pagina}`;
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: filtro || {},
      });
      if ([200, 201].includes(res.status)) {
        commit('setRaspadinhas', res.data.retorno.retorno);
        commit('setTotalizador', res.data.retorno.total);
        commit('setErro', null);
      } else if ([404].includes(res.status)) {
        commit('setRaspadinhas', []);
        commit('setTotalizador', 0);
        commit('setErro', null);
      } else {
        commit('setTotalizador', 0);
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('switchCarregando', false);
      resolve();
    });
  },
  fetchListagemRaspadinhas({ commit }, { campanha, filtro, paginacao, ordenacao }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/raspadinhas/${campanha.ano}/${campanha.identificacao}/${paginacao.porPagina}/${paginacao.pagina}`;
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          ...(filtro || {}),
          ...(ordenacao || {}),
        },
      });
      if ([200, 201].includes(res.status)) {
        commit('setListagemRaspadinhas', res.data.retorno.retorno);
        commit('setListagemRaspadinhaTotalizador', res.data.retorno.total);
        commit('setErro', null);
      } else if ([404].includes(res.status)) {
        commit('setListagemRaspadinhas', []);
        commit('setListagemRaspadinhaTotalizador', 0);
        commit('setErro', null);
      } else {
        commit('setTotalizador', 0);
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('switchCarregando', false);
      resolve();
    });
  },
  fetchRaspadinhasPorUsuario({ commit }, { campanha, usuario }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/raspadinhas/${campanha.ano}/${campanha.identificacao}/${usuario}`;
      const res = await comunicacao.send(endpoint, {
        method: 'GET',
      });
      if ([200, 201].includes(res.status)) {
        commit('setRaspadinhasUsuario', res.data.retorno);
        commit('setErro', null);
        resolve({
          status: 200,
          mensagem: 'Lista de cupons do usuário recuperada com sucesso',
          retorno: res.data,
        });
      } else if ([404].includes(res.status)) {
        commit('setRaspadinhasUsuario', []);
        commit('setErro', null);
      } else {
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('switchCarregando', false);
      resolve();
    });
  },
  cancelarPremio({ commit }, { identificacao, justificativa }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/raspadinhas/cancelarPremio/${identificacao}`;
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: { justificativa },
      });
      if ([200, 201].includes(res.status)) {
        commit('switchCarregando', false);
        commit('setErro', null);
        resolve({
          status: 200,
          mensagem: 'O Prêmio dessa raspadinha foi marcado como cancelado com sucesso!',
        });
      } else {
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
        commit('switchCarregando', false);
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
        });
      }
    });
  },
  entregarPremio({ commit }, { identificacao, extra }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();

      const baseEndpoint = `/raspadinhaAlternativa/v1/raspadinhas/${identificacao}`;
      const endpoint = extra ? `${baseEndpoint}?extra=${extra}` : baseEndpoint;

      const res = await comunicacao.send(endpoint);
      if ([200, 201].includes(res.status)) {
        commit('switchCarregando', false);
        commit('setErro', null);
        resolve({
          status: 200,
          data: res.data,
          mensagem: 'O Prêmio dessa raspadinha foi marcado como entregue com sucesso!',
        });
      } else {
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
        commit('switchCarregando', false);
        resolve({
          status: res.status || 500,
          data: res.data,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
        });
      }
    });
  },
  entregarPremioPicPay({ commit }, { identificacao, extra }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();

      const baseEndpoint = `/raspadinhaAlternativa/v1/picPay/${identificacao}`;
      const endpoint = extra ? `${baseEndpoint}?extra=${extra}` : baseEndpoint;

      const res = await comunicacao.send(endpoint);
      if ([200, 201].includes(res.status)) {
        commit('switchCarregando', false);
        commit('setErro', null);
        resolve({
          status: 200,
          data: res.data,
          mensagem: 'O Prêmio dessa raspadinha foi marcado como entregue e transferido com sucesso!',
        });
      } else {
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
        commit('switchCarregando', false);
        resolve({
          status: res.status || 500,
          data: res.data,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
        });
      }
    });
  },

  entregarPremioOnline({ commit }, { identificacao, extra }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);

      const comunicacao = new Vue.prototype.$comunicacao();

      const baseEndpoint = `/raspadinhaAlternativa/v1/entregarPremioOnline/${identificacao}`;
      const endpoint = extra ? `${baseEndpoint}?extra=${extra}` : baseEndpoint;

      const response = await comunicacao.send(endpoint);

      if ([200, 201].includes(response.status)) {
        commit('switchCarregando', false);
        commit('setErro', null);
        resolve({
          status: 200,
          data: response.data,
          mensagem: 'O Prêmio dessa raspadinha foi marcado como entregue e transferido com sucesso!',
        });
      } else {
        commit(
          'setErro',
          response.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
        commit('switchCarregando', false);
        resolve({
          status: response.status || 500,
          data: response.data,
          mensagem:
            response.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
        });
      }
    });

    // return new Promise(async (resolve) => {
    //   commit('switchCarregando', true);

    //   try {
    //     const baseEndpoint = `http://localhost/nissan/raspadinhaAlternativa/v1/entregarPremioOnline/${identificacao}`;
    //     const endpoint = extra ? `${baseEndpoint}?extra=${extra}` : baseEndpoint;

    //     const response = await fetch(endpoint, {
    //       method: 'GET', // Altere para 'POST', 'PUT' ou outro método, caso necessário
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijg4OGY2OTE2LTBhODEtNGIyYy04YzIyLTYxNzIzNTMzMjAzYyIsInVzdWFyaW8iOiJyb290Iiwibm9tZSI6IlNVUEVSVVNFUiIsInRpcG8iOiJyb290IiwiaWF0IjoxNzM0MDMyNTYyLCJleHAiOjE3NDk1ODQ1NjJ9.MdqyRIDWmkSqAAWTNaVgKZOmROUlCXGgPv5DbkaZjJQ'
    //       },
    //     });

    //     const responseData = await response.json();

    //     if ([200, 201].includes(response.status)) {
    //       commit('switchCarregando', false);
    //       commit('setErro', null);
    //       resolve({
    //         status: response.status,
    //         data: responseData,
    //         mensagem: 'O Prêmio dessa raspadinha foi marcado como entregue e transferido com sucesso!',
    //       });
    //     } else {
    //       const errorMessage =
    //         responseData.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!';
    //       commit('setErro', errorMessage);
    //       commit('switchCarregando', false);
    //       resolve({
    //         status: response.status || 500,
    //         data: responseData,
    //         mensagem: errorMessage,
    //       });
    //     }
    //   } catch (error) {
    //     const fallbackMessage = 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!';
    //     commit('setErro', fallbackMessage);
    //     commit('switchCarregando', false);
    //     resolve({
    //       status: 500,
    //       data: null,
    //       mensagem: fallbackMessage,
    //     });
    //   }
    // });
  },

  fetchScratchCanvass({ commit }, { campanha, filtro, paginacao }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/raspadinhas/${campanha.ano}/${campanha.identificacao}/${paginacao.porPagina}/${paginacao.pagina}`;
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: filtro || {},
      });
      if ([200, 201].includes(res.status)) {
        commit('setScratchesCanvass', res.data.retorno.retorno[0]);
        commit('setScratchesCanvassTotal', res.data.retorno.total);
        commit('setErro', null);
      } else if ([404].includes(res.status)) {
        commit('setScratchesCanvass', null);
        commit('setScratchesCanvassTotal', 0);
        commit('setErro', null);
      } else {
        commit('setTotalizador', 0);
        commit(
          'setErro',
          res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!'
        );
      }
      commit('switchCarregando', false);
      resolve();
    });
  },
  scratchCanvass({ commit }, { idPremiacao, apuracaoStatus, descricao }) {
    return new Promise(async (resolve) => {
      commit('switchCarregando', true);
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/raspadinhaAlternativa/v1/apurar/${idPremiacao}`;
      const res = await comunicacao.send(endpoint, {
        method: 'POST',
        data: {
          apuracaoStatus,
          descricao,
        },
      });
      commit('switchCarregando', false);
      if ([200, 201].includes(res.status)) {
        resolve({
          status: 200,
          data: res.data,
          mensagem: 'Status de apuração do prêmio instantâneo feito com sucesso!',
        });
      } else {
        resolve({
          status: res.status || 500,
          data: res.data,
          mensagem: res.data.mensagem || 'Ops.. ocorreu um erro na conexão com o servidor, tente novamente mais tarde!',
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setRaspadinhas(state, data) {
    state.raspadinhas = data;
  },
  setListagemRaspadinhas(state, data) {
    state.listagemRaspadinhas = data;
  },
  setRaspadinhasUsuario(state, data) {
    state.raspadinhasUsuario = data;
  },
  setTotalizador(state, data) {
    state.totalRaspadinha = data;
  },
  setListagemRaspadinhaTotalizador(state, data) {
    state.totalListagemRaspadinha = data;
  },
  setScratchesCanvass(state, data) {
    state.scratchCanvassUnit = data;
  },
  setScratchesCanvassTotal(state, data) {
    state.scratchCanvassTotal = data;
  },
  setQueryScratch(state, data) {
    state.queryScratch = data;
  },
  switchCarregando(state, data) {
    state.carregando = data;
  },
  setErro(state, data) {
    state.erro = data;
  },
};

// Exporting store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
