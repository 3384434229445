export default [
  {
    titulo: 'Home',
    condicao: () => true,
    icone: 'fas fa-home',
    caminho: 'cashback',
    paginas: [],
  },
  {
    titulo: 'Cadastro',
    condicao: () => true,
    icone: 'fas fa-plus',
    paginas: [
      {
        titulo: () => 'Campanha Cashback',
        condicao: () => true,
        icone: 'fas fa-shopping-bag',
        caminho: 'listaCampanhaCashback',
      },
      {
        titulo: () => 'Provedores de Voucher',
        condicao: () => true,
        icone: 'fa fa-ticket-alt',
        caminho: 'ProvedoresDeVoucher',
      },
      {
        titulo: () => 'Realizar cashout',
        condicao: () => true,
        icone: 'fa fa-gift',
        caminho: 'trocarPontos',
      },
      {
        titulo: () => 'Controle de Produtos',
        condicao: () => true,
        icone: 'fa fa-cart-plus',
        caminho: 'ControleProdutos',
      },
      {
        titulo: () => 'Controle de Estoque',
        condicao: () => true,
        icone: 'fas fa-box',
        caminho: 'controleEstoque',
      },
      {
        titulo: () => 'Controle de Pedidos',
        condicao: () => true,
        icone: 'fas fa-box-open',
        caminho: 'controlePedidos',
      },
      {
        titulo: () => 'Produtos',
        condicao: (configuracoes) => configuracoes.MODULO_PRODUTOS,
        icone: 'fas fa-box',
        caminho: 'produtos',
      },
      {
        titulo: () => 'Responsáveis',
        condicao: () => true,
        icone: 'fas fa-users-cog',
        caminho: 'responsaveis',
      },
      {
        titulo: () => 'Colaboradores',
        condicao: () => true,
        icone: 'fas fa-user-tie',
        caminho: 'colaboradores',
      },
      {
        titulo: () => 'Questionário',
        condicao: (configuracoes) => configuracoes.MODULO_QUESTIONARIO,
        icone: 'fas fa-question',
        caminho: 'questionario',
      },
      {
        titulo: () => 'Lojas',
        condicao: (configuracoes) => configuracoes.MODULO_ADESOES,
        icone: 'fas fa-handshake',
        caminho: 'adesoes',
      },
      {
        titulo: () => 'Compras',
        condicao: (configuracoes) => configuracoes.MODULO_CADASTRO_COMPRAS,
        icone: 'ni ni-cart',
        caminho: 'compras',
      },
      {
        titulo: () => 'Consumidores',
        condicao: (configuracoes) => configuracoes.MODULO_CONSUMIDORES,
        icone: 'fas fa-user',
        caminho: 'consumidores',
      },
      {
        titulo: () => 'Indústrias',
        condicao: (configuracoes) => configuracoes.MODULO_INDUSTRIAS,
        icone: 'fas fa-industry',
        caminho: 'industrias',
      },
      {
        titulo: () => 'Redes',
        condicao: (configuracoes) => configuracoes.MODULO_REDES,
        icone: 'fas fa-igloo',
        caminho: 'redes',
      },
      {
        titulo: () => 'Logins',
        condicao: () => true,
        icone: 'fas fa-key',
        caminho: 'logins',
      },
      {
        titulo: () => 'Importar vendas',
        condicao: (configuracoes) => configuracoes.MODULO_CADASTRO_COMPRAS,
        icone: 'fas fa-cloud-upload-alt',
        caminho: 'importarVendas',
      },
      {
        titulo: () => 'Importar Cashin',
        condicao: () => true,
        icone: 'fas fa-cloud-upload-alt',
        caminho: 'ImportarCashin',
      },
      {
        titulo: () => 'Importar Cashout',
        condicao: () => true,
        icone: 'fas fa-cloud-upload-alt',
        caminho: 'ImportarCashout',
      },
      {
        titulo: () => 'Grupos',
        condicao: () => true,
        icone: 'fas fa-users',
        caminho: 'grupos',
      },
      {
        titulo: () => 'Aceites',
        condicao: () => true,
        icone: 'fas fa-file-contract',
        caminho: 'aceites',
      },
    ],
  },
  {
    titulo: 'Consultas',
    condicao: () => true,
    icone: 'fas fa-search',
    paginas: [
      {
        titulo: () => 'Cedulas',
        condicao: (configuracoes) => configuracoes.MODULO_QUESTIONARIO,
        icone: 'fas fa-comment-alt',
        caminho: 'cedulas',
      },
      {
        titulo: () => 'Pedidos de Voucher',
        condicao: () => true,
        icone: 'fa fa-ticket-alt',
        caminho: 'pedidosVoucher',
      },
      {
        titulo: () => 'Ranking de engajamento de lojas',
        condicao: () => true,
        icone: 'fas fa-trophy',
        caminho: 'rankingLojas',
      },
      {
        titulo: () => 'Ranking de setores',
        condicao: () => true,
        icone: 'fas fa-trophy',
        caminho: 'resultadoSetorDepartamento',
      },
      {
        titulo: () => 'Investimento por campanha',
        condicao: (configuracoes) => configuracoes.MODULO_LISTAR_INVESTIMENTO_POR_CAMPANHA,
        icone: 'fas fa-chart-line',
        caminho: 'investimentoporcampanha',
      },
      {
        titulo: () => 'Vendas',
        condicao: (configuracoes) => configuracoes.MODULO_DOCUMENTOS_FISCAIS,
        icone: 'fas fa-file-invoice',
        caminho: 'documentosFiscais',
      },
      {
        titulo: () => 'Extrato Cashback',
        condicao: () => true,
        icone: 'fas fa-receipt',
        caminho: 'extratoCashback',
      },
      {
        titulo: () => 'Saldos Cashback',
        condicao: () => true,
        icone: 'fas fa-money-bill-wave',
        caminho: 'saldosCashback',
      },
      {
        titulo: () => 'Resultado dos vendedores',
        condicao: () => true,
        icone: 'fas fa-user-check',
        caminho: 'resultadoVendedores',
      },
    ],
  },
  {
    titulo: 'Documento',
    icone: 'fas fa-file',
    condicao: (configuracoes) => configuracoes.MODULO_DOCUMENTOS_FISCAIS,
    paginas: [
      {
        titulo: () => 'Documentos Fiscais Origem',
        condicao: (configuracoes) => configuracoes.MODULO_DOCUMENTOS_FISCAIS && configuracoes.MODULO_DOC_FISCAL_ORIGEM,
        icone: 'fas fa-file-alt',
        caminho: 'documentosFiscaisOrigem',
      },
      {
        titulo: () => 'Documentos Fiscais Pendentes',
        condicao: (configuracoes) => configuracoes.MODULO_DOCUMENTOS_FISCAIS,
        icone: 'fas fa-file-powerpoint',
        caminho: 'documentosFiscaisPendentes',
      },
    ],
  },
  {
    titulo: 'Área de relacionamento',
    condicao: (configuracoes) => configuracoes.MODULO_CHAT,
    icone: 'fas fa-user-friends',
    paginas: [
      {
        titulo: () => 'Chat',
        condicao: () => true,
        icone: 'fas fa-comments',
        caminho: 'chat',
      },
    ],
  },
  {
    titulo: 'Ajuda',
    condicao: (configuracoes) => configuracoes.MODULO_EMAIL,
    icone: 'fas fa-question',
    paginas: [
      {
        titulo: () => 'Modelo de email',
        condicao: () => true,
        icone: 'fas fa-envelope',
        caminho: 'email',
      },
    ],
  },
  {
    titulo: 'Relatórios',
    condicao: () => true,
    icone: 'fas fa-file-excel',
    paginas: [
      {
        titulo: () => 'Listar',
        condicao: () => true,
        icone: 'fas fa-list',
        caminho: 'listar-relatorios',
      },
    ],
  },
  {
    titulo: 'Suporte',
    condicao: (configuracoes) => configuracoes.MODULO_SUPORTE || configuracoes.LINK_SAC != null,
    icone: 'fas fa-life-ring',
    paginas: [
      {
        titulo: () => 'SAC',
        condicao: (configuracoes) => configuracoes.LINK_SAC,
        icone: 'fas fa-headset',
        caminho: 'this.configuracoes.LINK_SAC',
      },
      {
        titulo: () => 'Suporte online',
        condicao: (configuracoes) => configuracoes.MODULO_SUPORTE,
        icone: 'fas fa-life-ring',
        caminho: 'suporte',
      },
    ],
  },
];
