/* eslint-disable no-console */
import Vue from 'vue';

const initialState = () => ({
  loading: false,
  missoes: [],
  missao: {},
  modeDeEdicao: false,
  modeDeVisualizacao: false,
  niveis: [],
  nivel: {},
  modeDeEdicaoDeNiveis: false,
  modeDeVisualizacaoDeNiveis: false,
});

const state = initialState();

const getters = {
  getLoadingState(state) {
    return state.loading;
  },

  getMissoes(state) {
    return state.missoes;
  },

  getModeDeEdicao(state) {
    return state.modeDeEdicao;
  },

  getModeDeVisualizacao(state) {
    return state.modeDeVisualizacao;
  },

  getMissao(state) {
    return state.missao;
  },

  getNiveis(state) {
    return state.niveis;
  },

  getNivel(state) {
    return state.nivel;
  },

  getModeDeEdicaoDeNiveis(state) {
    return state.modeDeEdicaoDeNiveis;
  },

  getModeDeVisualizacaoDeNiveis(state) {
    return state.modeDeVisualizacaoDeNiveis;
  },
};

const actions = {
  fetchMissoes({ commit }, { pagina, filtro }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();

      const endpoint = `/gameficacao/v1/missao/${pagina.total}/${pagina.atual}`;

      commit('setLoadingState', true);

      if (filtro) {
        if (filtro.dataHoraInicial === null) delete filtro.dataHoraInicial;
        if (filtro.dataHoraFinal === null) delete filtro.dataHoraFinal;
      }

      const res = await comunicacao.send(endpoint, {
        method: 'GET',
        data: filtro,
      });

      if (res.status == 200) {
        commit('setLoadingState', false);
        commit('setMissoes', res.data.retorno);
        resolve({
          status: 200,
          mensagem: 'Lista de missões recuperada com sucesso',
          missoes: res.data.retorno,
        });
      } else {
        commit('setLoadingState', false);
        commit('setMissoes', []);
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição para obter a lista de missões',
        });
      }
    });
  },

  deletarMissao({ commit, dispatch }, id) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();

      const endpoint = `/gameficacao/v1/missao/${id}`;

      commit('setLoadingState', true);

      const res = await comunicacao.send(endpoint, {
        method: 'DELETE',
      });

      if (res.status == 200) {
        dispatch('removeMissaoDaLista', id);

        commit('setLoadingState', false);
        resolve({
          status: 200,
          mensagem: 'Missão deletada com sucesso',
          missoes: res.data.retorno,
        });
      } else {
        commit('setLoadingState', false);
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição para obter a lista de missões',
        });
      }
    });
  },

  cadastrarMissao({ commit }, { dados }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/gameficacao/v1/missao`;

      commit('setLoadingState', true);

      const payload = {
        nome: dados.nome,
        descricao: dados.descricao,
        icone: dados.icone,
        dataHoraInicial: dados.dataHoraInicial,
        dataHoraFinal: dados.dataHoraFinal,
        condicoes: dados.condicoes,
        objetivos: dados.objetivos,
        recompensas: dados.recompensas,
        segmentacoes: dados.segmentacoes,
      };

      const resposta = await comunicacao.send(endpoint, {
        method: 'POST',
        data: payload,
      });

      if (resposta.data.status == 200) {
        commit('setLoadingState', false);
        resolve({ status: 200, mensagem: resposta.data.mensagem });
      } else {
        commit('setLoadingState', false);
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem, dados });
      }
    });
  },

  editarMissao({ commit }, { dados, id }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/gameficacao/v1/missao/${id}`;

      commit('setLoadingState', true);

      const payload = {
        nome: dados.nome,
        descricao: dados.descricao,
        icone: dados.icone,
        dataHoraInicial: dados.dataHoraInicial,
        dataHoraFinal: dados.dataHoraFinal,
        condicoes: dados.condicoes,
        objetivos: dados.objetivos,
        recompensas: dados.recompensas,
        segmentacoes: dados.segmentacoes,
      };

      const resposta = await comunicacao.send(endpoint, {
        method: 'PUT',
        data: { ...payload },
      });

      if (resposta.data.status == 200) {
        commit('setLoadingState', false);
        resolve({ status: 200, mensagem: resposta.data.mensagem });
      } else {
        commit('setLoadingState', false);
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem, dados });
      }
    });
  },

  removeMissaoDaLista({ commit, state }, id) {
    const missoes = state.missoes.lista.filter((missao) => missao.id !== id);

    commit('setMissoes', { ...state.missoes, lista: missoes });
  },

  cadastrarNivel({ commit }, { dados }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/gameficacao/v1/nivel`;

      commit('setLoadingState', true);

      const payload = {
        nome: dados.nome,
        descricao: dados.descricao,
        icone: dados.icone,
        quantidadeXP: dados.quantidadeXP,
        beneficios: dados.beneficios,
      };

      const resposta = await comunicacao.send(endpoint, {
        method: 'POST',
        data: payload,
      });

      if (resposta.data.status == 200) {
        commit('setLoadingState', false);
        resolve({ status: 200, mensagem: resposta.data.mensagem });
      } else {
        commit('setLoadingState', false);
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem, dados });
      }
    });
  },

  editarNivel({ commit }, { dados, id }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();
      const endpoint = `/gameficacao/v1/nivel/${id}`;

      commit('setLoadingState', true);

      const payload = {
        nome: dados.nome,
        descricao: dados.descricao,
        icone: dados.icone,
        quantidadeXP: dados.quantidadeXP,
        beneficios: dados.beneficios,
      };

      const resposta = await comunicacao.send(endpoint, {
        method: 'PUT',
        data: { ...payload },
      });

      if (resposta.data.status == 200) {
        commit('setLoadingState', false);
        resolve({ status: 200, mensagem: resposta.data.mensagem });
      } else {
        commit('setLoadingState', false);
        resolve({ status: resposta.data.status, mensagem: resposta.data.mensagem, dados });
      }
    });
  },

  fetchNiveis({ commit }, { pagina, filtro }) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();

      const endpoint = `/gameficacao/v1/nivel/${pagina.total}/${pagina.atual}`;

      commit('setLoadingState', true);

      const res = await comunicacao.send(endpoint, {
        method: 'GET',
        data: filtro,
      });

      if (res.status == 200) {
        commit('setLoadingState', false);
        commit('setNiveis', res.data.retorno);
        resolve({
          status: 200,
          mensagem: 'Lista de níveis recuperada com sucesso',
          missoes: res.data.retorno,
        });
      } else {
        commit('setLoadingState', false);
        commit('setNiveis', []);
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição para obter a lista de missões',
        });
      }
    });
  },

  removeNivelDaLista({ commit, state }, id) {
    const niveis = state.niveis.retorno.filter((missao) => missao.id !== id);

    commit('setNiveis', { ...state.niveis, retorno: niveis });
  },

  deletarNivel({ commit, dispatch }, id) {
    return new Promise(async (resolve) => {
      const comunicacao = new Vue.prototype.$comunicacao();

      const endpoint = `/gameficacao/v1/nivel/${id}`;

      commit('setLoadingState', true);

      const res = await comunicacao.send(endpoint, {
        method: 'DELETE',
      });

      if (res.status == 200) {
        dispatch('removeNivelDaLista', id);

        commit('setLoadingState', false);
        resolve({
          status: 200,
          mensagem: 'Nivel deletada com sucesso',
          missoes: res.data.retorno,
        });
      } else {
        commit('setLoadingState', false);
        resolve({
          status: res.status || 500,
          mensagem: res.data.mensagem || 'Ocorreu um problema durante a requisição para obter a lista de missões',
        });
      }
    });
  },
};

const mutations = {
  setLoadingState(state, data) {
    state.loading = data;
  },

  setMissoes(state, data) {
    state.missoes = data;
  },

  setModeDeEdicao(state, data) {
    state.modeDeEdicao = data;
  },

  setModeDeVisualizacao(state, data) {
    state.modeDeVisualizacao = data;
  },

  setMissao(state, data) {
    state.missao = data;
  },

  setNiveis(state, data) {
    state.niveis = data;
  },

  setNivel(state, data) {
    state.nivel = data;
  },

  setModeDeEdicaoDeNiveis(state, data) {
    state.modeDeEdicaoDeNiveis = data;
  },

  setModeDeVisualizacaoDeNiveis(state, data) {
    state.modeDeVisualizacaoDeNiveis = data;
  },
};

export default { namespaced: true, state, getters, actions, mutations };
